import axios from "axios";

// set token to the axios
export const setAuthToken = (token, usuario, ipUsuario) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["usuario"] = usuario;
    axios.defaults.headers.common["aplicacion"] = "DocumentosAdicionales2.0";
    axios.defaults.headers.common["ipUsuario"] = ipUsuario;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};


/**
 * API /documentosAdicionales/obtenerTramitesPermitidos
 * -- capacidad que permite obtener los tramites que son permitidos cargar
 * @param {*} url 
 * @returns 
 */
export const obtenerTramitesPermitidos = async (url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerTramitesPermitidos`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 * API /obtenerParametroPorTipoParametro/{tipoParametro}
 * -- capacidad que permite obtener los parametros por código
 * @param {*} url 
 * @returns 
 */
export const obtenerParametrosPorCodigo = async (tipoParametro,url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerParametroPorTipoParametro/${tipoParametro}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/actualizarTramitePermitido
 *  -- capacidad que permite actualizar los datos de un trámite espesífico
 */
export const actualizarTramitePermitidoService = async (url, obj) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/actualizarTramitePermitido`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/actualizarTramitePermitido
 *  -- capacidad que permite adicionar un nuevo trámite
 */
export const agregarTramitePermitidoService = async (url, obj) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/agregarTramitePermitido`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/consultarDocumentoAdicionalActivos
 *  -- consultar documentos adicionales activos
 */
export const obtenerDocumentoAdicionalActivos = async (url, obj) => {
  try {
    const response = await axios.post(`${url}/documentosAdicionales/consultarDocumentoAdicionalActivos`, obj);
    return response.data;
  } catch (err) {
    // Captura errores con más detalle
    return {
      error: true,
      response: err.response ? err.response.data : null,
      message: err.message,
    };
  }
};

/**
 *  API /documentosAdicionales/consultarDocumentoAdicionalActivos
 *  -- radicar documentos adicionales activos faltantes
 */
export const radicarDocumentoAdicionalActivos = async (url,paramRequest) => {
  try {
    const response = await axios.post(`${url}/documentosAdicionales/radicarTramite`,paramRequest);
    return response.data;
  } catch (err) {
    // Captura errores con más detalle
    return {
      error: true,
      response: err.response ? err.response.data : null,
      message: err.message,
    };
  }
};