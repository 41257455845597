import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import "../govco/src/form/accordion.css";
import "../govco/src/form/tables.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from 'react-paginate';
import {
  // obtenerDocumentoAdicionalActivos,
  // actualizarTramitePermitidoService,
  // agregarTramitePermitidoService,
} from "../services/consulta";
import { useHistory } from "react-router-dom";
import "../govco/src/form/button_app.css";
import { radicarDocumentoAdicionalActivos } from "../services/consulta.js";

const TablaRadicacion = ({ datos, actualizarDatos }) => {
  // const buttonStyle = {
  //   width: "110px",
  //   height: "42px",
  //   backGroundColor: "#EBF5FB !important",
  // };

  const authObj = useSelector((state) => state.auth);
  const { baseUrl } = authObj;
  const [disable, setDisable] = useState(true);
  const [index, setIndex] = useState(null);
  const [textoGuardar, setTextoGuardar] = useState("Texto Guardar");
  const showPopupTramites = useFormInput(false);
  const tituloTramite = useFormInput("");
  const codigo = useFormInput("");
  const idTramite = useFormInput("");
  const tramite = useFormInput("");
  const descripcion = useFormInput("");
  const tamanioMaxDoc = useFormInput("");
  const selEstadoTramite = useFormInput("");
  const selEstadoActivo = useFormInput("");
  const optionsEstadoTramites = useFormInput([]);
  const optionsEstadoInactivo = useFormInput([]);
  const mensaje = useFormInput(false);
  // const showError = useFormInput(false);
  const history = useHistory();
  const showSucess = useFormInput(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [numeroRadicacion, setNumeroRadicacion] = useState("");
  const [mensajeError, setmensajeError] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  const handleShow = () => setShow(true);
  const handleShowError = () => setShowError(true);
  const showSpinner = useFormInput(false);


  const spinnerStyle = {
    width: "90px",
    height: "90px",
    borderWidth: "8px",
    position: 'absolute',
    left: '50%',
    top: '50%'
  }

  const handleClose = () => {
    setShow(false);
    const updatedDatos = datos.map(dato => {
      if (selectedFiles[dato.descripcion]) {
        return { ...dato, radicadoCargado: numeroRadicacion };
      }
      return dato;
    });

    actualizarDatos(updatedDatos);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleFileChange = (event, dato) => {
    const selectedFile = event.target.files[0];
    setSelectedFiles({
      ...selectedFiles,
      [dato.descripcion]: selectedFile
    });
  };

  const handleRadicar = (dato) => {
    const role = getAuthorities(authObj.access_token);
    const file = selectedFiles[dato.descripcion];
    const formData = new FormData();
    let paramRequest = {
      "numeroRadicado": dato.radicacion,
      "anio":dato.anio,
      "numeroIdentificacion": dato.numeroIdentificacion,
      "codigoTramite":dato.codigo,
      "role":role
      };
    formData.append('file', file);
    formData.append('request', JSON.stringify(paramRequest));
    /*formData.append('numeroIdentificacion', Number(dato.numeroIdentificacion));
    formData.append('anio', Number(dato.anio));
    formData.append('numeroRadicado', dato.radicacion);
    formData.append('tramite', Number(dato.codigo));
    formData.append('role', role);*/
    console.log(`Radicar para el documento: ${dato.descripcion}`);
    console.log("Radicando archivo:", file);
    console.log("Rol:", role);
    modradicarDocumentoAdicionalActivos(formData);
  };

  const modradicarDocumentoAdicionalActivos = async (paramRequest) => {
    showSpinner.onChange({ target: { value: true } });  
    const result = await radicarDocumentoAdicionalActivos(baseUrl,paramRequest);
    // alert(JSON.stringify(result));    
    if (result.estado==1) {
      mensaje.onChange({
        target: {
          value:
            "Ha ocurrido un error en el servicio, por favor intenta mas tarde.",
        },
      });
      mostrarModalError(result.mensaje);
    } else {
      console.log(result.objeto);
      mostrarModal(result.objeto);
      // optionsDocumentoAdicionales.onChange({target: { value: result.objeto },});
      // setMostrarTabla(result.objeto.length > 0);
    }
    showSpinner.onChange({ target: { value: false } }); 
  
  };

  const mostrarModal = (resultado) => {
    setNumeroRadicacion(resultado); // Actualiza el estado de numeroRadicacion
    handleShow(); // Muestra el modal
  };

  const mostrarModalError = (resultado) => {
    setmensajeError(resultado);
    handleShowError();
  };


  const decodeJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log('jsonPayload', JSON.parse(jsonPayload))
    return JSON.parse(jsonPayload);
  }

  const getAuthorities = (token) => {
    const { authorities } = decodeJWT(token);
    return authorities[0];
  }

  
const changePage = ({ selected }) => {
  setPageNumber(selected);
};


  return (
    <div>
      <div className="table-simple-headblue-govco">
        <table
          className="table display table-responsive-sm table-responsive-md"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Tramite</th>
              <th>Documento</th>
              <th>Radicado</th>
            </tr>
          </thead>
          <tbody>
            {datos
              .slice(pagesVisited, pagesVisited + itemsPerPage)
              .map((dato, index) => (
                <tr key={index} className="hoverSelect">
                  <td>{dato.codigo}</td>
                  <td>{dato.descripcion}</td>
                  <td>
                    {dato.radicadoCargado ? (
                      dato.radicadoCargado
                    ) : (
                      <>
                        <input type="file" accept=".pdf" onChange={(event) => handleFileChange(event, dato)} style={{ display: 'block', marginBottom: '10px' }} />
                        <button className="btn-govco outline-btn-govco" onClick={() => handleRadicar(dato)} disabled={!selectedFiles[dato.descripcion]}>Radicar</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Siguiente'}
          pageCount={Math.ceil(datos.length / itemsPerPage)}
          onPageChange={changePage}
          containerClassName={'pagination pagination-container-bootstrap'}
          activeClassName={'active'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
        />
      </div>

     <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton className="modal-header-govco modal-header-alerts-govco">
          <Modal.Title className="modal-title-govco success-govco">Radicación Exitosa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body modal-body-govco center-elements-govco">
            <div className="modal-icon">
              <span className="modal-icon-govco modal-success-icon"></span>
            </div>
            <p className="modal-text-govco modal-text-center-govco">El número de radicado es: {numeroRadicacion}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-govco modal-footer-alerts-govco d-flex justify-content-center">
          <Button variant="primary" className="btn btn-primary btn-modal-govco btn-contorno" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showError} onHide={handleCloseError} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton className="modal-header-govco modal-header-alerts-govco">
          <Modal.Title className="modal-title-govco error-govco">Error al Radicar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body modal-body-govco center-elements-govco">
            <div className="modal-icon">
              <span className="modal-icon-govco modal-error-icon"></span>
            </div>
            <p className="modal-text-govco modal-text-center-govco">Error: {mensajeError}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-govco modal-footer-alerts-govco d-flex justify-content-center">
          <Button variant="primary" className="btn btn-primary btn-modal-govco btn-contorno" onClick={handleCloseError}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>       

    <Modal size="sm" show={showSpinner.value} className="transparencia" aria-labelledby="example-modal-sizes-title-sm"  >

          <Modal.Body style={{ background: "transparent" }}>
            <div className="spinner-indicador-de-carga-govco" style={spinnerStyle} role="status" aria-hidden="true">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
};


// custom hook to manage the form input
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};


export default TablaRadicacion;
