import React, { useEffect, useState, Component, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "../services/consulta.js";
import { fileLoadConfigAsync } from "../asyncActions/configAsyncActions.js";
import { useHistory } from "react-router-dom";


//Hojas de estilos
import "../govco/src/form/carga-de-archivo.css";
import "../govco/src/transversal/barra-superior.css";
import "../govco/src/transversal/pie-de-pagina.css";
import "../govco/src/form/entradas-de-texto.css";
import "../govco/src/general/botones.css";
import "../govco/src/general/alerta-modal.css";
import "../govco/src/general/tablas.css";
import "../govco/src/general/indicador-de-carga.css";
import "../govco/src/transversal/barra-accesibilidad.css";
import "../govco/src/general/etiqueta.css";
import "../govco/src/general/tables.css";
import * as entrada from "../govco/src/form/entradas-de-texto.js";
import * as ip from "../lib/GetIP.js";
import TablaRadicacion from "../components/RadicacionTable.js";
import "../govco/src/form/button_app.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
/** Imports APP DocumentosAdicionales */
import { obtenerDocumentoAdicionalActivos } from "../services/consulta.js";

function FormularioRadicacion() {
  const history = useHistory();

  const dispatch = useDispatch();
  const authObj = useSelector((state) => state.auth);
  const { access_token, expires_in, userName, baseUrl } = authObj;

  const [radicacion, setRadicacion] = useState('');
  const [anio, setAnio] = useState('');

  const optionsDocumentoAdicionales = useFormInput([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [show, setShow] = useState(false);
  const [mensajeError, setmensajeError] = useState("");
  const handleShow = () => setShow(true);
  const [errorMessage, setErrorMessage] = useState('');
  const showSpinner = useFormInput(false);

  const obj = {
    numeroIdentificacion: userName,
    anio: anio,
    numeroRadicado: radicacion,
  };

  const spinnerStyle = {
    width: "90px",
    height: "90px",
    borderWidth: "8px",
    position: 'absolute',
    left: '50%',
    top: '50%'
  }


  const currentYear = new Date().getFullYear();

  const handleConsult = () => {
    
    if (!radicacion || !anio) {
      setErrorMessage("Por favor, ingresa el número de radicación y el año.");
      return;
    }

    if (anio < 1990 || anio > currentYear) {
      setErrorMessage(`El año debe estar entre 1990 y ${currentYear}`);
      return;
    }
    mthDocumentoAdicionales();
  };

  const handleClear = () => {
    setRadicacion('');
    setAnio('');
    optionsDocumentoAdicionales.onChange({ target: { value: [] }, });
    setMostrarTabla(false);
  };

  const mthDocumentoAdicionales = async (e) => {
    showSpinner.onChange({ target: { value: true } });  
    const result = await obtenerDocumentoAdicionalActivos(baseUrl, obj);
    if (result.response===null) {     
      mostrarModal('El servicio no se encuentra disponible, por favor intente más tarde.');
    }
    else if (result.estado==1) {     
      mostrarModal(result.mensaje);
      showSpinner.onChange({ target: { value: false } });
    } else {
      result.objeto.forEach(item => {
        item.numeroIdentificacion = userName;
        item.anio = anio;
        item.radicacion = radicacion;
      });
      optionsDocumentoAdicionales.onChange({ target: { value: result.objeto }, });
      setMostrarTabla(result.objeto.length > 0);
      showSpinner.onChange({ target: { value: false } });  
    }
  };

  useEffect(() => {
    entrada.initInput();
    ip.getIPs();
    dispatch(fileLoadConfigAsync());
    const verifyTokenTimer = setTimeout(() => {
      if (sessionStorage.getItem("ip") != null) {
        clearTimeout(verifyTokenTimer);
        setAuthToken(access_token, userName, sessionStorage.getItem("ip"));
      }
    }, 1000);
    return () => {
      clearTimeout(verifyTokenTimer);
    };
  }, []);

  function volver() {
    history.goBack();
  }

  const handleClose = () => {
    setShow(false);
  };

  const mostrarModal = (resultado) => {
    setmensajeError(resultado);
    handleShow();
    setMostrarTabla(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 mx-auto text-left">
          <h2>Consultar Radicación</h2>
          <div className="entradas-de-texto-govco">
            <label className={`col-sm-6 col-form-label ${errorMessage && !radicacion ? 'error-input' : ''}`}>
              Número de Radicación:
              <input
                type="text"
                value={radicacion}
                onChange={(e) => setRadicacion(e.target.value)}
              />
              {errorMessage && !radicacion && <p className="error-message" style={{ color: 'red' }}>Por favor, ingresa el número de radicación.</p>}
            </label>
            <label className={`col-sm-6 col-form-label ${errorMessage && !anio ? 'error-input' : ''}`}>
              Año:
              <input
                type="number"
                min="1990"
                max={currentYear}
                value={anio}
                onChange={(e) => setAnio(e.target.value)}
              />
              {errorMessage && !anio && <p className="error-message" style={{ color: 'red' }}>Por favor, ingresa el año.</p>}
              {errorMessage && (anio < 1990 || anio > currentYear) && <p className="error-message" style={{ color: 'red' }}>El año debe estar entre 1990 y {currentYear}.</p>}
            </label>
          </div>
          <div style={{ marginTop: '20px' }}>
            <button className="btn-govco fill-btn-govco" onClick={handleConsult}>Consultar</button>
            <button className="btn-govco fill-btn-govco" onClick={handleClear} style={{ marginLeft: '10px' }}>Limpiar</button>
          </div>
        </div>
      </div>
      {mostrarTabla && (
        <div className="row">
          <div className="col-lg-10 mx-auto text-left">
            <br />
            <TablaRadicacion datos={optionsDocumentoAdicionales.value} actualizarDatos={mthDocumentoAdicionales} />
          </div>
          <br />
          <br />
          <br />
          <div className="col-lg-10 mx-auto text-left">
            <button type="button" icon-position="left" className="btn-govco fill-btn-govco" onClick={volver}>
              <span className="sub-btn-govco">Volver</span>
            </button>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton className="modal-header-govco modal-header-alerts-govco">
          <Modal.Title className="modal-title-govco error-govco">Error al realizar consulta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body modal-body-govco center-elements-govco">
            <div className="modal-icon">
              <span className="modal-icon-govco modal-error-icon"></span>
            </div>
            <p className="modal-text-govco modal-text-center-govco">Error: {mensajeError}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-govco modal-footer-alerts-govco d-flex justify-content-center">
          <Button variant="primary" className="btn btn-primary btn-modal-govco btn-contorno" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="sm" show={showSpinner.value} className="transparencia" aria-labelledby="example-modal-sizes-title-sm"  >

          <Modal.Body style={{ background: "transparent" }}>
            <div className="spinner-indicador-de-carga-govco" style={spinnerStyle} role="status" aria-hidden="true">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );


}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default FormularioRadicacion;
