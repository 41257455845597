import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
// grafico
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
//Hojas de estilos
import "../govco/src/transversal/barra-accesibilidad.css";
import "../govco/src/general/etiqueta.css"
import "../govco/src/form/opcion-de-seleccion.css"
import "../govco/src/transversal/pie-de-pagina.css"
import "../govco/src/transversal/barra-superior.css"
import "../govco/src/form/entradas-de-texto.css";
import "../govco/src/general/botones.css"
import "../govco/src/general/alerta-modal.css";
import "../govco/src/general/tablas.css"

//Componentes
import { userLoginService } from '../services/auth';
import { verifyUserSuccess, } from "../actions/authActions";
import { fileLoadConfigAsync } from '../asyncActions/configAsyncActions';

function Login() {
  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { loginError, baseUrl, llaveCAPTCHA } = authObj;
  const username = useFormInput('');
  const password = useFormInput('');
  const show = useFormInput(false);
  const showError = useFormInput(false);
  const mensaje = useFormInput("");
  const showSpinner = useFormInput(false);

  // accion para iniciar sesion
  const handleLogin = async () => {
    if(!captcha.current.getValue()){
      console.log('Por favor acepta el captcha');
      cambiarUsuarioValido(false);
      cambiarCaptchaValido(false);
      mensaje.onChange({ target: { value: "Por favor acepta el captcha" } })
      showError.onChange({ target: { value: true } });
    }else{
      cambiarUsuarioValido(true);
      cambiarCaptchaValido(true);
      showSpinner.onChange({ target: { value: true } });      
      if (username.value+" "+password.value) {
        const result = await userLoginService(username.value, password.value, baseUrl);
        //Consumo de servicio de Registro de Usuario
        if (result.error) {
          mensaje.onChange({ target: { value: "Por favor valida tu usuario y/o contraseña" } })
          showError.onChange({ target: { value: true } });
        } else {
          let obj = { access_token: result.data.access_token, expires_in: result.data.expires_in, nombreCompleto: result.data.nombreCompleto, userName: username.value };
          sessionStorage.setItem("usuario", JSON.stringify(obj));
          sessionStorage.setItem("estaAutenticado", true);
          dispatch(verifyUserSuccess(obj));
        }
        showSpinner.onChange({ target: { value: false } });
      } else {
        mensaje.onChange({ target: { value: "Verifica los parametros requeridos: Asunto, Cuerpo, destinario en archivo o individual." } })
        showError.onChange({ target: { value: true } });
      }
   }
  }

  const handleCloseError = () => { showError.onChange({ target: { value: false } }); };
  const errorClassUser = () => {
    return (username.value.length === 0 ? 'text-danger' : 'ocultarCampo');
  }


  const errorClassPass = () => {
    return (password.value.length === 0 ? 'text-danger' : 'ocultarCampo');
  }

  const validateField = () => {
    return (password.value.length === 0 && username.value.length === 0);
  }

  const showPassword = () => {
    show.onChange({ target: { value: !show.value } });
  }


  const buttonStyle = {
    width: "1650px",
    height: "42px"
  }

  const spinnerStyle = {
    width: "90px",
    height: "90px",
    borderWidth: "8px",
    position: 'absolute',
    left: '50%',
    top: '50%'
  }


  const [captchaValido, cambiarCaptchaValido] = useState(null);
  const [usuarioValido, cambiarUsuarioValido] = useState(false);
  const captcha = useRef(null);

  const onChange = () => {
    if(captcha.current.getValue()){
      console.log('El usuario no es un robot');
      cambiarCaptchaValido(true);
    }
  }

  return (
    <div>
      <div className="govco-form-signin col-lg-4 col-md-6 col-12 my-4 p-5">
          <h3 className="govco-title-sign-form headline-l-govco">Inicio de sesión</h3> 
        <div className="form-group gov-co-form-group">
          <div className="gov-co-label-counter">
            <label htmlFor="exampleCountinput">Usuario</label>
          </div>
          <input type="text" className="form-control gov-co-form-control" id="usuario" {...username}
          />
          <span className={`${errorClassUser()}`}>
            Usuario es requerido *
          </span>
        </div>

        <div className="form-group gov-co-form-group">
          <div className="gov-co-label-counter">
            <label htmlFor="contrasena">Contraseña</label>
          </div>
          <input type={show.value ? 'text' : 'password'} {...password} className="form-control gov-co-form-control" id="password"
            required />
          <span className={`${errorClassPass()}`}>
            Contraseña es requerido *
          </span>
        </div>

        <div className="checkbox-seleccion-govco m-2">
          <input type="checkbox" id="checkboxPassword-govco" className="form-check-input" onClick={showPassword} />
          <label className="label_checkbox" htmlFor="checkboxPassword-govco"> Mostrar contraseña</label>
        </div>

        <div className='recaptcha'>
        <ReCAPTCHA
            ref={captcha}
            sitekey={llaveCAPTCHA}            
            onChange={onChange}
          />
        </div>
        {captchaValido == false && 
            <div className={`${errorClassPass()}`}>Por favor acepta el captcha</div>
        }
        <br></br>
        <div className="d-flex d-flex justify-content-between">
          <input
            className="btn-govco fill-btn-govco"
            type="button"
            style={buttonStyle}
            value={'INICIAR SESIÓN'}
            onClick={handleLogin}
            disabled={validateField()} />
          {loginError && <div style={{ color: 'red', marginTop: 10 }}>{loginError}</div>}


        </div>

        <p className="govco-p-sign-form"><a className="btn btn-font-gov-co" href={process.env.REACT_APP_RU} >Regístrese aquí</a>
          <a className="btn btn-font-gov-co" href={process.env.REACT_APP_RU} >Olvido su contraseña?</a>
          <br></br><br></br>
          <div className="d-flex justify-content-center text-muted"><label className="d-flex" style={{ fontSize: 10}}>Versión 2.1.0</label></div>
        </p>
        

        <Modal show={showError.value} onHide={handleCloseError}>

          <Modal.Body><div className="modal-body modal-body-govco center-elements-govco">
            <div className="modal-icon">
              <span className="modal-icon-govco modal-error-icon"></span>
            </div>
            <h3 className="modal-title-govco error-govco">
              Error del Sistema
            </h3>
            <p className="modal-text-govco modal-text-center-govco">
              {mensaje.value}
            </p>
          </div></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="btn btn-primary btn-modal-govco" onClick={handleCloseError}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="sm" show={showSpinner.value} className="transparencia" aria-labelledby="example-modal-sizes-title-sm"  >

          <Modal.Body style={{ background: "transparent" }}>
            <div className="spinner-indicador-de-carga-govco" style={spinnerStyle} role="status" aria-hidden="true">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div >
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
export default Login;